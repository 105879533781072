body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}
header,
footer {
  background: rgb(241 241 241 / 30%);
  padding: 30px 20px;
  text-align: center;
}
header {
  clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
}
@media (max-width: 768px) {
  header {
    clip-path: polygon(0 0, 100% 0, 100% 95%, 50% 100%, 0 95%);
  }
}
header h1,
footer p {
  margin: 0;
}

main {
  padding: 20px;
  max-width: 600px;
  margin: 0px auto 66px auto;
  min-height: 70vh;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 10px 0;
}
a {
  color: #2a3849;
  font-weight: 700;
  padding: 16px;
  text-align: center;
  text-transform: uppercase;
}
.more {
  margin: 10px 0;
  padding: 0;
}
.more span {
  margin-right: 10px;
}
nav {
  max-width: 600px;
  margin: 0 auto;
}
nav ul {
  display: flex;
}
nav ul li {
  flex-grow: 1;
  margin: 0 1px;
  position: relative;
  padding: 10px 0px;
}
nav ul li a {
  text-decoration: none;
  font-weight: 300;
  padding: 10px;
}
header input {
  padding: 10px;
  margin-right: 5px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e6e6e6;
}
header button {
  padding: 10px;
  background-color: #2271b1;
  border-radius: 2px;
  position: relative;
  text-transform: none;
  color: white;
  border-color: #2271b1;
}
header form {
  margin: 10px 0px;
}
header h1 a {
  text-decoration: none;
}
main li a {
  padding-left: 0;
  font-weight: 400;
}
button {
  background: #2271b1;
  border-color: #2271b1;
  color: #fff;
  text-decoration: none;
  text-shadow: none;
  padding: 10px 20px;
  border: unset;
  border-radius: 99px;
}
button:hover {
  background: #1c64a0;
  border-color: #1c64a0;
}
button:disabled {
  background: rgb(241 241 241 / 29%);
  border-color: unset;
  color: black;
}
main li div {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
ul li {
  margin-bottom: 40px;
}
main ul li:not(:last-child) {
  border-bottom: solid 1px #efefef;
}
.Pager button:first-child {
  float: left;
}
.Pager button:last-child {
  float: right;
}
nav li a img {
  margin-right: 5px;
}
.desc {
  font-size: 13px;
}
/* 返回顶部按钮样式 */
.back-to-top {
  position: fixed;
  bottom: 120px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  cursor: pointer;
  z-index: 999999;
}

.back-to-top:hover {
  background-color: #555;
}

.back-to-top.show {
  opacity: 1;
  visibility: visible;
}
.noFound {
  font-size: 18px;
  text-align: center;
  margin: 200px auto;
  font-weight: bold;
}
.desc img {
  max-height: 100px;
}
.content img {
  max-width: 100%;
}
@media only screen and (max-device-width: 768px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2),
  only screen and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .desc {
    max-height: 100px;
  }
}
.MuiLinearProgress-root {
  margin: 25vh auto;
}
